<template>
   <section class="cont room-state-cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>PMS</el-breadcrumb-item>
            <el-breadcrumb-item>房态中心</el-breadcrumb-item>
            <el-breadcrumb-item>实时房态</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="content-flex-box">
         <!-- 左侧 -->
         <el-col :span="6" class="left-table-bg">
            <el-row class="left-roomState">
               <el-row class="building">
                  <el-input class="m-bottom-15"  v-model="condition" placeholder="姓名/房号" @keyup.enter.native="getRoomList"></el-input>
                  <el-cascader
                        v-model="buildUnitId"
                        :options="buildList"
                        @change="changeBuilding"
                        clearable
                        placeholder="选择楼栋/单元"
                        class="m-bottom-15">
                  </el-cascader>
                  <el-select class="m-bottom-15" v-model="floor" @change="changeFloor" placeholder="选择楼层" clearable>
                     <el-option
                           v-for="item in floorList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="room-state">
                  <el-row class="room-state-title">
                     <span>房态</span>
                     <span @click="resetPmsRoomState">重置</span>
                  </el-row>
                  <el-row class="check-box">
                     <el-checkbox-group v-model="pmsRoomStateEnums" @change="getRoomList">
                        <el-checkbox label="VACANT_ROOM">空房 <span class="text-decora">{{ roomStateObj.vacantRoomSum || 0 }}</span><i></i></el-checkbox>
                        <el-checkbox label="LIVE_IN">在住  <span class="text-decora">{{ roomStateObj.liveInSum || 0 }}</span><i class="i2"></i></el-checkbox>
                        <el-checkbox label="RESERVE">预定  <span class="text-decora">{{ roomStateObj.reserveSum || 0 }}</span><i class="i3"></i></el-checkbox>
                        <el-checkbox label="RETAIN">保留  <span class="text-decora">{{ roomStateObj.retainSum || 0 }}</span><i class="i4"></i></el-checkbox>
                        <el-checkbox label="NOT_CLEAN_ROOM">脏房  <span class="text-decora">{{ roomStateObj.notCleanRoomSum || 0 }}</span><i class="i5"></i></el-checkbox>
                        <el-checkbox label="REPAIR_ROOM">维修  <span class="text-decora">{{ roomStateObj.repairRoomSum || 0 }}</span><i class="i6"></i></el-checkbox>
                     </el-checkbox-group>
                     <el-row class="border-top">
                        <el-checkbox v-model="isArrive" @change="getRoomList">今日抵达 <span class="text-decora">{{ arriveCount }}</span></el-checkbox>
                        <el-checkbox v-model="isDeparture" @change="getRoomList">今日预离 <span class="text-decora">{{ departureCount }}</span></el-checkbox>
                     </el-row>
                  </el-row>
               </el-row>
               <el-row class="room-type">
                  <el-row class="room-state-title">
                     <span style="letter-spacing: 5px;">房型  | 可订数/总房数</span>
                     <span @click="resetPmsRoomType">重置</span>
                  </el-row>
                  <el-row class="check-box">
                     <el-checkbox-group v-model="roomTypeIds">
                        <el-checkbox
                              v-for="(item, index) in roomTypeList"
                              :key="index" :label="item.id"
                              @change="getRoomList">
                           <span>{{ item.roomTypeName }}</span>
                          <span class="text-decora"> {{ item.reserveTotalNum || 0 }} / {{ item.roomTotalNum || 0 }}</span>
                        </el-checkbox>
                     </el-checkbox-group>
                  </el-row>
               </el-row>
            </el-row>
         </el-col>
         <!-- 右侧 -->
         <el-col :span="18" class="right-table-bg">
            <el-row class="right-table">
                  <el-row class="room-list">
                     <div v-for="(item, index) in roomList" :key="index"
                          @contextmenu="showMenu(item)"
                          :class="['room-item',
                                    item.state.substr(1,1) === '1' ? 'live-in' : '',
                                    item.state.substr(2,1) === '1' ? 'reserve' : '',
                                    item.state.substr(3,1) === '1' ? 'retain' : '',
                                    item.state.substr(4,1) === '1' ? 'dirty' : '',
                                    item.state.substr(5,1) === '1' ? 'repair' : '',]">
                        <span :class="['room-state-tags',
                        item.state.substr(1,1)==='1' ? 'live-ins' : '',
                        item.state.substr(2,1) === '1' ? 'reserves' : '',
                        item.state.substr(3,1) === '1' ? 'retains' : '',
                        item.state.substr(4,1) === '1' ? 'dirtys' : '',
                        item.state.substr(5,1) === '1' ? 'repairs' : '',]">{{textEvent(item.state)}}</span>
                        <el-row class="top">
                           <p>{{ item.roomNo }}</p>
                           <span>{{ item.roomTypeName }}</span>
                        </el-row>
                        <el-row class="btm">
                           <span v-if="item.state.substr(1,1) === '1' && item.hasOwnProperty('orderRowhouse')">
                              {{ item.orderRowhouse.checkinPersonList[0].name }}
                           </span>
                           <span v-else>{{ item.state | filterState }}</span>
                        </el-row>
                     </div>
                     <vue-context-menu
                           :contextMenuData="contextMenuData"
                           @checkIn="checkIn" @reserve="reserve" class="contextMenuData-box"
                           @setRetain="visibleRetain = true" @checkOut="visibleCheckOut = true"
                           @exchangeRoom="visibleExchange = true" @setCleanRoom="changeState('CLEAN_ROOM')"
                           @setDirtyRoom="changeState('NOT_CLEAN_ROOM')" @setRepair="changeState('REPAIR_ROOM')"
                           @cancelRetain="changeState('VACANT_ROOM')" @cancelReserve="cancelReserve">
                     </vue-context-menu>
                  </el-row>
            </el-row>
         </el-col>
         <!-- 办理退房 -->
         <el-dialog
             class="check-out"
             title="办理退房"
             :visible.sync="visibleCheckOut"
             width="40%">
            <el-row class="check-out-cont">
               <!--<el-checkbox v-model="checked">原房间：大床房，1001，已入住</el-checkbox>-->
               <span>原房间：{{ roomInfo.roomTypeName }}，{{ roomInfo.roomNo }}，已入住</span>
               <span class="m-left-25" v-if="roomInfo.hasOwnProperty('orderRowhouse')">住宿时间：{{ roomInfo.orderRowhouse.beginTime  }}---{{ roomInfo.orderRowhouse.endTime }} 总共1晚，已入住1晚</span>
            </el-row>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="visibleCheckOut = false">取 消</el-button>
               <el-button  type="primary" @click="saveCheckOut">确 定</el-button>
            </span>
         </el-dialog>
         <!-- 办理换房 -->
         <el-dialog
             class="check-out"
             title="办理换房"
             :before-close="closeExchange"
             :visible.sync="visibleExchange"
             width="40%">
            <el-row class="check-out-cont">
               <!--<el-checkbox v-model="checked">原房间：大床房，1001，已入住</el-checkbox>-->
               <el-row class="m-bottom-20">
                  <span>原房间：{{ roomInfo.roomTypeName }}，{{ roomInfo.roomNo }}，已入住</span>
                  <span class="m-left-25" v-if="roomInfo.hasOwnProperty('orderRowhouse')">住宿时间：{{ roomInfo.orderRowhouse.beginTime }}-{{ roomInfo.orderRowhouse.endTime }} 总共1晚，已入住1晚</span>
               </el-row>
               <el-row>
                  <label>换房至：</label>
                  <el-select @change="getRoomOpt"  v-model="roomTypeIdName" placeholder="请选择房型">
                     <el-option
                         v-for="item in roomTypeList"
                         :key="item.id"
                         :label="item.roomTypeName"
                         :value="item.id + ',' + item.roomTypeName">
                     </el-option>
                  </el-select>
                  <el-select class="m-left-5"  v-model="roomIdNo" placeholder="请选择房间">
                     <el-option
                         v-for="item in roomOpt"
                         :key="item.id"
                         :label="item.roomNo"
                         :value="item.id + ',' + item.roomNo"
                         :disabled="!item.state">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="visibleExchange = false">取 消</el-button>
               <el-button  type="primary" @click="exchangeRoom">确 定</el-button>
            </span>
         </el-dialog>
         <!-- 设置保留 -->
         <el-dialog title="设置保留" :visible.sync="visibleRetain" width="550px">
            <el-form class="form-dialog-box">
               <el-form-item label="保留时间：" required >
                  <el-date-picker
                        class="width-220" v-model="dateValue"
                        type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" value-format="yyyy-MM-dd">
                  </el-date-picker>
               </el-form-item>
               <el-form-item label="房型名称：" required>
                  <span>{{ roomInfo.roomTypeName }}</span>
               </el-form-item>
               <el-form-item label="房间号：" required>
                  <span> {{ roomInfo.roomNo }}</span>
               </el-form-item>
               <el-form-item label="备注：">
                  <el-input  class="width-200"  type="textarea" v-model="remark" :placeholder="$t('msg.remark')" clearable></el-input>
               </el-form-item>
          </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button  @click="visibleRetain = false">取消</el-button>
                <el-button  type="primary" @click="saveRetain" v-text="$t('msg.save')">保存</el-button>
           </span>
      </el-dialog>
      </el-row>
   </section>
</template>
<script>
   import { mapState } from 'vuex'
   import { urlObj  } from '@/api/interface';
   import { dateFactory } from '@/common/js/common';
   import { pms } from '@/api/interface/pms';
   export default {
      data(){
         return{
            buildList: [],             // 楼栋列表
            floorList: [],             // 楼层列表
            floor: '',                 // 楼层
            buildUnitId: [],           // 楼栋/单元ID
            roomList: [],              // 房间列表
            roomOpt: [],               // 房间列表
            pmsRoomStateEnums: [],     // 房态多选框
            roomTypeList: [],          // 房型列表
            roomTypeIds: [],           // 房型ID
            roomInfo: {
               orderRowhouse: {}
            },              // 当前房间信息
            roomTypeIdName: '',        // 换房房型ID名称
            roomIdNo: '',              // 换房房号ID房号
            remark: '',                // 保留房备注
            dateValue: [],             // 保留时间段
            roomStateObj: {
               liveInSum: 0,           // 在住
               notCleanRoomSum: 0,     // 脏房
               repairRoomSum: 0,       // 维修
               reserveSum: 0,          // 预订
               retainSum: 0,           // 保留
               vacantRoomSum: 0        // 空房
            },
            arriveCount: 0,            // 今日预抵数
            departureCount: 0,         // 今日预离数
            condition: '',             // 姓名/房号
            isArrive: false,
            isDeparture: false,
            visibleCheckOut: false,
            visibleRetain: false,
            visibleExchange: false,
            // contextmenu data (菜单数据)
            contextMenuData: {
               // the contextmenu name(@1.4.1 updated)
               menuName: "demo",
               // The coordinates of the display(菜单显示的位置)
               axis: {
                  x: null,
                  y: null
               },
               menulists: []
            },
            menuTotalObj: {
               checkIn: {
                  fnHandler: "checkIn", // Binding events(绑定事件)
                  icoName: "fa fa-home fa-fw", // icon (icon图标 )
                  btnName: "办理入住" // The name of the menu option (菜单名称)
               },
               reserve: {
                  fnHandler: "reserve", // Binding events(绑定事件)
                  icoName: "fa fa-home fa-fw", // icon (icon图标 )
                  btnName: "办理预订" // The name of the menu option (菜单名称)
               },
               setRepair: {
                  fnHandler: "setRepair",
                  icoName: "fa fa-home fa-fw",
                  btnName: "设为维修"
               },
               setRetain: {
                  fnHandler: "setRetain",
                  icoName: "fa fa-home fa-fw",
                  btnName: "设置保留"
               },
               checkOut: {
                  fnHandler: "checkOut",
                  icoName: "fa fa-home fa-fw",
                  btnName: "办理退房"
               },
               exchangeRoom: {
                  fnHandler: "exchangeRoom",
                  icoName: "fa fa-home fa-fw",
                  btnName: "办理换房"
               },
               cancelReserve: {
                  fnHandler: "cancelReserve",
                  icoName: "fa fa-home fa-fw",
                  btnName: "取消预订"
               },
               cancelRetain: {
                  fnHandler: "cancelRetain",
                  icoName: "fa fa-home fa-fw",
                  btnName: "取消保留"
               },
               setCleanRoom: {
                  fnHandler: "setCleanRoom",
                  icoName: "fa fa-home fa-fw",
                  btnName: "设为净房"
               },
               setDirtyRoom: {
                  fnHandler: "setDirtyRoom",
                  icoName: "fa fa-home fa-fw",
                  btnName: "设为脏房"
               },
            },
            /* 提示文本 */
            hotelId_no_null: '请选择酒店！',
            parentId_no_null: '请选择词库！',
            wordName_no_null: '词条值不能为空！',
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_change: '确定操作？',
            prompt: '提示！',
            release_success: '发布成功！',
            checkOut_success: '退房成功！',
            exchangeRoom_success: '换房成功！',
            setRetain_success: '设置保留成功！',
            change_success: '状态更改成功！'
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.getBuildings()
         this.getHousingStatistics()
         this.getArriveDepartureTotal()
         this.getRoomList()
         this.getPmsRoomTypes()
      },
      methods: {
         textEvent(state){
            if(state.substr(1,1)==='1'){
               return "住"
            }else if(state.substr(2,1)==='1'){
               return "订"
            }if(state.substr(3,1)==='1'){
               return "留"
            }if(state.substr(4,1)==='1'){
               return "脏"
            }if(state.substr(5,1)==='1'){
               return "修"
            }
         },
         // 获取楼栋列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelInfo.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item1 => {
                     let children = []
                     if (item1.buildUnits.length > 0){
                        children = item1.buildUnits.map(item2 => {
                           return {value: item2.id, label: item2.unitName}
                        })
                     }
                     return {value: item1.id, label: item1.buildName, children}
                  })
               }
            })
         },
         // 获取楼栋单元层级
         changeBuilding(val){
            this.getFloorList(val[1])
         },
        // 获取当前楼层列表
         getFloorList(unitVal){
            const url = urlObj.getUnit + `/${unitVal}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return
                  let floorList = []
                  let obj = {}
                  for (let i = beginFloor; i <= endFloor; i++) {
                     obj.value = i
                     obj.label = i
                     floorList.push(obj)
                     obj = {}
                  }
                  if (data.excludeFloor) {
                     let excludeFloor = data.excludeFloor.split(',')
                     if (excludeFloor.length > 0) {
                        for (let i = floorList.length - 1; i >= 0; i -- ) {
                           if (excludeFloor.includes(String(floorList[i].value))) {
                              floorList.splice(i, 1)
                           }
                        }
                     }
                  }
                  this.floorList = floorList
               }
            })
         },
         // 改变当前楼层
         changeFloor() {
            this.getRoomList()
         },
         // 获取房态统计
         getHousingStatistics(){
            const url = pms.housingStatistics
            const param = { hotelId: this.hotelInfo.id }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  if (res.records) {
                     this.roomStateObj = res.records
                  }else {
                     this.roomStateObj = []
                  }
               }
            })
         },
         // 获取抵达预离统计
         getArriveDepartureTotal(){
            const url = pms.arriveDepartureTotal
            const param = {
               hotelId: this.hotelInfo.id,
               queryDate: dateFactory.dateFormat(false),
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  let data = res.records
                  if (data) {
                     this.arriveCount = data.arriveCount
                     this.departureCount = data.departureCount
                  }else {
                     this.arriveCount = 0
                     this.departureCount = 0
                  }
               }
            })
         },
         // 重置房态选中
         resetPmsRoomState(){
            this.pmsRoomStateEnums = []
            this.getRoomList()
         },
         // 获取房型列表
         getPmsRoomTypes(){
            const url = pms.pmsRoomTypes
            const param = { hotelId: this.hotelInfo.id, isCheckInNum: true }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  let roomTypeList = res.records
                  this.roomTypeList = roomTypeList.map(item => {
                     return {
                        id: item.id,
                        roomTypeName: item.roomTypeName,
                        checkinNum: item.checkinNum,
                        roomTotalNum: item.roomTotalNum,
                        reserveTotalNum: item.reserveTotalNum
                     }
                  })
               }
            })
         },
         // 获取房间列表
         getRoomList(){
            const url = pms.pmsRoomList
            const param = {
               hotelId: this.hotelInfo.id,
               condition: this.condition,
               roomTypeIds: this.roomTypeIds,
               isCheckin: true,
               buildUnitId: this.buildUnitId[1],
               floor: this.floor,
               isDeparture: this.isDeparture,
               isArrive: this.isArrive,
               pmsRoomStateEnums: this.pmsRoomStateEnums
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) this.roomList = res.records
            })
         },
         // 获取房间下拉列表
         getRoomOpt() {
            const url = pms.pmsRoomList
            const param = {
               roomTypeIds: this.roomTypeIdName.split(',').splice(0,1)
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  let roomOpt = res.records
                  this.roomOpt = roomOpt.map(item => {
                     return { roomNo: item.roomNo, id: item.id, state:item.state.substr(0, 1) === '1' }
                  })
               }
            })
         },
         // 重置房型选中
         resetPmsRoomType(){
            this.roomTypeIds = []
            this.getRoomList()
         },
         // 右键点击显示菜单栏
         showMenu(row) {
            event.preventDefault();
            let x = event.clientX;
            let y = event.clientY;
            // Get the current location
            this.contextMenuData.axis = { x, y }
            // 获取右键点击当前房间信息
            this.roomInfo = row
            this.contextMenuData.menulists = []
            if (this.roomInfo.state.substr(0, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.checkIn,
                   this.menuTotalObj.reserve,
                   this.menuTotalObj.setDirtyRoom,
                   this.menuTotalObj.setRepair,
                   this.menuTotalObj.setRetain
               )
            } else if (this.roomInfo.state.substr(1, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.exchangeRoom,
                   this.menuTotalObj.checkOut,
                   this.menuTotalObj.setDirtyRoom,
                   this.menuTotalObj.setRepair,
               )
            } else if (this.roomInfo.state.substr(2, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.checkIn,
                   this.menuTotalObj.cancelReserve,
                   this.menuTotalObj.setDirtyRoom,
                   this.menuTotalObj.setRepair
               )
            } else if (this.roomInfo.state.substr(3, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.setDirtyRoom,
                   this.menuTotalObj.setRepair,
                   this.menuTotalObj.cancelRetain
               )
            } else if (this.roomInfo.state.substr(4, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.checkIn,
                   this.menuTotalObj.reserve,
                   this.menuTotalObj.setCleanRoom,
                   this.menuTotalObj.setRepair,
                   this.menuTotalObj.setRetain
               )
            } else if (this.roomInfo.state.substr(5, 1) === '1') {
               this.contextMenuData.menulists.push(
                   this.menuTotalObj.checkIn,
                   this.menuTotalObj.reserve,
                   this.menuTotalObj.setCleanRoom,
                   this.menuTotalObj.setDirtyRoom,
                   this.menuTotalObj.setRetain
               )
            }
         },
         // 办理入住
         checkIn() {
            sessionStorage.setItem('roomInfo', JSON.stringify(this.roomInfo))
            this.$router.push('/room_state/check_in')
         },
         // 办理退房
         saveCheckOut() {
            const url = pms.pmsCheckOut
            const param = {
               id: this.roomInfo.orderRowhouse.id,
               roomId: this.roomInfo.id,
               checkInState: 'CHECKOUT',
               checkOutTime: dateFactory.dateFormat(),
               hotelId: this.hotelInfo.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.checkOut_success,
                     type: 'success'
                  })
                  this.visibleCheckOut = false
                  this.getHousingStatistics()
                  this.getRoomList()
               }
            })
         },
         // 办理预订
         reserve() {
            sessionStorage.setItem('roomInfo', JSON.stringify(this.roomInfo))
            this.$router.push({ path: '/add_reserve', query: { state: 'roomState' } })
         },
         // 取消预订
         cancelReserve() {
            this.$confirm('是否取消预定单？取消后，支付的金额将原路退回。', '取消预定单', {
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning'
            }).then(() => {
               const url = pms.cancelReserve+  `/${this.roomInfo.orderRowhouse.id}`
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     this.getRoomList()
                     this.$message({
                        type: 'success',
                        message: '已取消!'
                     });
                  }
               })
            })
         },
         // 办理换房
         exchangeRoom() {
            let [ roomTypeId, roomTypeName ] = this.roomTypeIdName.split(',')
            let [ roomId, roomNo ] = this.roomIdNo.split(',')
            const url = pms.pmsExchangeRoom
            const param = {
               id: this.roomInfo.orderRowhouse.id,
               roomTypeName, roomId,
               roomTypeId, roomNo
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.roomIdNo = "";
                  this.roomTypeIdName = "";
                  this.$message({
                     showClose: true,
                     message: this.exchangeRoom_success,
                     type: 'success'
                  })
                  this.visibleExchange = false
                  this.getHousingStatistics()
                  this.getRoomList()
               }
            })
         },
         // 关闭换房弹窗
         closeExchange(){
            this.visibleExchange = false
            this.roomTypeIdName = ''
            this.roomIdNo = ''
         },
         // 设置保留
         saveRetain() {
            const url = pms.pmsSetRetain
            const param = {
               retainBeginTime: this.dateValue[0],
               retainEndTime: this.dateValue[1],
               id: this.roomInfo.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.setRetain_success,
                     type: 'success'
                  })
                  this.visibleRetain = false
                  this.getHousingStatistics()
                  this.getRoomList()
               }
            })
         },
         // 更改房间状态
         changeState(pmsRoomState) {
            this.$confirm(this.confirm_change, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = pms.changRoomState
               const param = { id: this.roomInfo.id, pmsRoomState }
               this.$axios.get(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.change_success,
                        type: 'success'
                     })
                  }
                  this.getHousingStatistics()
                  this.getRoomList()
               })
            })
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getBuildings()
                  this.getHousingStatistics()
                  this.getArriveDepartureTotal()
                  this.getRoomList()
                  this.getPmsRoomTypes()
               }
            },
            deep: true
         }
      },
      filters: {
         filterState(val){
            if (val.substr(0, 1) === '1') {
               return '空房'
            }
            if (val.substr(1, 1) === '1') {
               return '入住'
            }
            if (val.substr(2, 1) === '1') {
               return '预定'
            }
            if (val.substr(3, 1) === '1') {
               return '保留'
            }
            if (val.substr(4, 1) === '1') {
               return '脏房'
            }
            if (val.substr(5, 1) === '1') {
               return '维修'
            }
         }
      }
   }
</script>

<style scoped lang="scss">
.text-decora{width: 20px;display: inline-block;text-decoration:underline;}
.left-roomState{
   .building{
      text-align: center;
      .el-select,.el-cascader{ width: 100% }
   }
   .room-state{
      .check-box{
         padding: 0 10px; box-sizing: border-box;
         .el-checkbox{  padding: 5px 0px;}
         .el-checkbox-group{ padding: 10px 0;}
         .border-top { border-top: solid #f0f0f0 1px; padding: 10px 0px 20px 0px;}
         i{
            width: 12px; height: 12px; border: solid 1px #999999; display: inline-block;
            border-radius: 50%; margin: 0 0 0 5px;
         }
         .i2{ border-color: #0c7ffd; background: #0c7ffd }
         .i3{ border-color: #4ED3B5; background: #4ED3B5 }
         .i4{ border-color: #F56C6C; background: #F56C6C }
         .i5{ border-color: #aaaaaa; background: #aaaaaa }
         .i6{ border-color: #FF9913; background: #FF9913 }
      }
   }
   .room-state-title{
      background: #dfefff; line-height: 40px; padding: 0 20px;
      font-weight: bold; font-size: 16px;
      color: #2D2D2D;
      span:nth-child(2){
         float: right; color: #087ffd; font-weight: normal; font-size: 14px;
         cursor: pointer;
      }
   }
   .room-type{
      .check-box{
         padding: 10px; box-sizing: border-box;
         .el-checkbox{ margin-bottom: 10px; width: 100% }
         span{ max-width: 180px; display: inline-block; margin-right: 15px }
      }
   }
}
.room-list{
   display: flex; flex-wrap: wrap;
   margin-left: 5%;
   .room-item{
      position: relative;
      text-align: center; border-radius: 10px; border: 1px solid #BDBDBD; width: 130px;
      margin: 30px  38px 15px 38px;
      overflow: hidden; cursor: pointer;
      .room-state-tags{
         position: absolute; top: 10px;right: 10px;
         font-size: 12px;
         display: inline-block;
         width: 20px;
         height: 20px;
         line-height: 20px;
         border-radius: 20px;
      }
      .top{
         padding: 25px 0 0 0; font-family: Microsoft YaHei;color: #2D2D2D;
         p{ font-size: 18px;
            font-weight: bold;
            text-decoration: underline;
         }
         span{ padding: 10px 0; display: inline-block;font-size: 14px;font-weight: 400; }
      }
      .btm{
         line-height: 42px; border-top: solid 1px #aaaaaa; font-weight: 400;
         font-size: 18px; font-weight: bold; font-family: Microsoft YaHei;
         span{
            overflow: hidden;
            white-space: nowrap;
         }
      }
      .live-ins{ background: #2577E3; color: #FFFFFF }
      .reserves{background: #4ED3B5; color: #FFFFFF }
      .retains{ background: #F56C6C; color: #FFFFFF }
      .dirtys{ background: #aaaaaa; color: #FFFFFF  }
      .repairs{ background: #FF9913; color: #FFFFFF  }
   }
   .live-in{
      p{ color: #2577E3 }
      .btm{ background: #2577E3; color: #FFFFFF;}
   }
   .reserve{
      border-color: #4ED3B5;
      p{ color: #4ED3B5 }
      .btm{ background: #4ED3B5; color: #FFFFFF; border-color: #4ED3B5 }
   }
   .retain{
      border-color: #F56C6C;
      p{ color: #F56C6C }
      .btm{ background: #F56C6C; color: #FFFFFF; border-color: #F56C6C }
   }
   .dirty{
      border-color: #aaaaaa;
      p{ color: #aaaaaa }
      .btm{ background: #aaaaaa; color: #FFFFFF; border-color: #aaaaaa }
   }
   .repair{
      border-color: #FF9913;
      p{ color: #FF9913 }
      .btm{ background: #FF9913; color: #FFFFFF; border-color: #FF9913 }
   }
}
.check-out{
   .check-out-cont{ padding: 20px 0 }
}

.contextMenuData-box{
   width: 146px;
   height: auto;
   background: #FBFDFF;
   border: 1px solid #E5F0FF;
   box-shadow: 0px 3px 7px 0px rgba(150, 150, 150, 0.08);
   border-radius: 2px;
   ::v-deep .context-menu-list{
      padding: 12px 0px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
   }
}

</style>
